<template>
    <div>   
        <CForm ref="form" class="needs-validation"   >

            <CRow>
                <CCol col="3"><b>Koli No</b></CCol>
                <CCol col="9">
                  <CInput
                      type="number"
                      horizontal
                      v-model="data.packingNumber"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="3"><b>Sevk Yeri</b></CCol>
                <CCol col="9">
                  <CSelect
                      horizontal
                      placeholder="Sevk Yeri"
                      :options="shipmentLocationList"
                      :value.sync="data.shipmentLocation"
                      required
                  />
                </CCol>
              </CRow>
              
              <CRow v-for="(item, i) in productionGenerateItem " :key="i">
                <CCol col="3">
                  <b :id="item.name" :style="{color: item.amount == 0 ? 'red' : 'black'}"> {{ item.name }}</b>
                </CCol>
                <CCol col="9">
                  <CInput
                      type="number"
                      placeholder="0"
                      horizontal
                      v-model="data['sku'+(i+1)]"
                  />
                </CCol>
              </CRow>
              <!--
                <CRow>
                <CCol col="3"><b id="xs">XS</b></CCol>
                <CCol col="9">
                  <CInput
                      type="number"
                      placeholder="0"
                      horizontal
                      v-model="data.sku1"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="3"><b id="s">S</b></CCol>
                <CCol col="9">
                  <CInput
                      type="number"
                      placeholder="0"
                      horizontal
                      v-model="data.sku2"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="3">
                  <b id="m">M</b>
                </CCol>
                <CCol col="9">
                  <CInput
                      type="number"
                      placeholder="0"
                      horizontal
                      v-model="data.sku3"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="3"><b id="l">L</b></CCol>
                <CCol col="9">
                  <CInput
                      type="number"
                      placeholder="0"
                      horizontal
                      v-model="data.sku4"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="3"><b id="xl">XL</b></CCol>
                <CCol col="9">
                  <CInput
                      type="number"
                      placeholder="0"
                      horizontal
                      v-model="data.sku5"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="3"><b id="2xl">2XL</b></CCol>
                <CCol col="9">
                  <CInput
                      type="number"
                      placeholder="0"
                      horizontal
                      v-model="data.sku6"
                  />
                </CCol>
              </CRow>
              -->
              
        </CForm>
    </div>
</template>

<script>
   import 'vue-multiselect/dist/vue-multiselect.min.css'
   export default{
       name: "PackingItemForm",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create'){
                   this.data = {};
                   this.disabledAreas = false;
               }else{
                this.disabledAreas = true;
               }
           },
       },
       computed:{
        productionGenerateItem: function(){
          return this.$store.getters.productionGenerateItem
        }
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
               shipmentLocationList: ["LOJISTIK", "KAFT DEPO"],
           }
       },
   }
</script>